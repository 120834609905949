<template>
  <loading-icon v-if="loading" />
  <div id="wallet" class="tab-cnt" v-else-if="adsTransaction">
    <div class="wallet-box">
      <p class="subHeading">{{ $t('AdsWalletBalance') }}</p>
      <div class="amount-flex">
        <div class="wallet-amt">₹{{ adsTransaction.balance }}</div>
        <div class="load-amt">
          <span>₹</span>
          <input
            type="text"
            class="input-bg"
            v-model="amount"
            placeholder="Add"
          />
          <button :class="amount>0 ? 'button' : 'btnDisabled'" @click="createOrder" :disabled="amount<1">
            {{ $t('Load Wallet') }}
          </button>
        </div>
      </div>
    </div>

    <div class="wallet-history">
      <h2>{{ $t('Wallet History') }}</h2>
      <ul v-if="adsTransaction.adsTransactionHistory.length">
        <li
          v-for="(item, index) in adsTransaction.adsTransactionHistory"
          :key="index"
        >
          <div class="info-text">
            <div class="item">
              <h3>{{ item.comment }}</h3>
              <span class="text-date">{{new Date(item.date).toString().slice(0,25)}}</span>
            </div>
            <div class="item amt-val" v-if="item.comment.includes('credited') || item.comment.includes('credits')"> + ₹{{ item.amount }}</div>
            <div class="item amt-val-deb" v-if="item.comment.includes('debited')"> - ₹{{ item.amount }}</div>
          </div>
        </li>
      </ul>
    </div>
    <Toaster :dataValue="toasterData" />
  </div>
</template>
<script>
import { SessionHelper } from '../services/Session';
import { BASE_API_URL, BASE_URL2, CALLBACK_URL_COUPON } from '../utils/constants';
import Toaster from '../components/Toaster';
import ApiRequest from '../services/ApiRequest';
import LoadingIcon from '../components/icons/loadingIcon.vue';

export default {
  name: 'WalletHistory',
  data() {
    return {
      toasterData: {},
      isBalance: true,
      amount: this.adsTransaction && this.adsTransaction.balance < 1 ? 0 : 1,
      adsTransaction: null,
      loading: false,
    };
  },
  components: {
    Toaster,
    LoadingIcon
  },
  mounted() {
    this.fetchWalletDetails();
  },
  methods: {
    fetchWalletDetails() {
      this.loading = true;
      ApiRequest(BASE_API_URL,`/user/transaction-history`, "get").then((data) => {
        if (data.httpStatus === 200) {
          this.adsTransaction = data.data;
          SessionHelper.setCookie("loadWallet", "false");
          this.loading = false;
        } else {
          SessionHelper.setCookie("loadWallet", "false");
          this.toasterData = {
            status: true,
            msg: data.message,
            timeoutMs: "5000",
            color: "#000",
          };
          if(data.message === 'Invalid Token'){
            SessionHelper.refereshJwtToken()
            if(SessionHelper.getCookie('JWT-TOKEN') !== 'null'){
              this.fetchWalletDetails();
            }
          }
          this.loading = false;
        }
      }).catch(() => {
        this.loading = false;
      });
    },
    async createOrder() {
      let callbackurl = CALLBACK_URL_COUPON;
      if (window.location.host.indexOf('localhost') != -1) {
        callbackurl = 'http://localhost:8080/#/order-status';
      }
      if(this.amount < 1){
        this.toasterData = {
          status: true,
          msg: 'Amount should be greater than 0',
          timeoutMs: '5000',
          color: '#ff6600',
        };
        return;
      }
      const payload = {
        amount: this.amount,
        callBackUrl: callbackurl,
        itemName: 'Load Ads wallet',
      };
      try {
        const response = await ApiRequest(
          BASE_URL2,
          '/walletService/ads-wallet/order/create',
          'post',
          payload
        );
        window.location.href = `http://billsbe.payboard.in/api/v1/payment-service/doPay/${response.orderId}`;
      } catch (e) {
        console.log('error: ', e);
      }
    },
    loadWallet() {
      console.log(
        '=$cookies=',
        SessionHelper.getCookie('X-Auth-Id'),
        CALLBACK_URL_COUPON
      );
      if (SessionHelper.getCookie('X-Auth-Id')) {
        var callbackurl = null;
        SessionHelper.setCookie('loadWallet', 'true');
        if (window.location.host.indexOf('localhost') != -1) {
          callbackurl = encodeURIComponent(
            'http://localhost:8080/#/order-status'
          );
        } else {
          callbackurl = encodeURIComponent(CALLBACK_URL_COUPON);
        }
        window.location.href = `http://billsbe.payboard.in/load-db-wallet?encodedId=${SessionHelper.getCookie(
          'X-Auth-Id'
        )}&callbackUrl=${callbackurl}`;
      } else {
        this.toasterData = {
          status: true,
          msg: 'Invalid Authentication',
          timeoutMs: '5000',
          color: '#ff6600',
        };
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/dashboard";
.wallet-box {
  width: 92%;
  margin: 1em auto;
  padding: 16px;
  height: auto;
  border: 2px dashed $brdColor;
  border-radius: 8px;
  background: $white;

  // .button {
  //   // @extend .gotoButton;
  //   border-radius: 30px;
  //   width: auto;
  //   margin-top: 8px;
  //   background: $darkblue;
  //   font-weight: 600;
  //   font-size: 0.875em;
  // }

  .subHeading{
    font-size: .8em;
  }

  .amount-flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;

    .wallet-amt {
      font-size: 1.7em;
      color: $darkBlack;
      font-weight: bold;
    }
    .load-amt {
      position: relative;
      // display: flex;
      // align-items: center;
      width: 55%;
      // justify-content: center;
      span{
        position: absolute;
        top: .5em;
        left: .7em;
      }
      .input-bg {
        width: 100%;
        border-radius: 5px;
        border: 2px solid #ddd;
        padding: 6px 6px 6px 22px;
        font-size: .9em;
        font-weight: bold;
      }
      .button {
        margin-top: 0px;
        position: absolute;
        background: $darkblue;
        right: .275em;
        top: .27em;
        font-size: .8em;
        width: auto;
        padding: .5em 1em;
        height: auto;
        border-radius: .3em;
      }
      .btnDisabled {
        margin-top: 0px;
        position: absolute;
        background: #888;
        border: none;
        outline: none;
        color: white;
        right: .275em;
        top: .27em;
        font-size: .8em;
        width: auto;
        padding: .5em 1em;
        height: auto;
        border-radius: .3em;
      }
    }
  }
}

.wallet-history {
  width: 92%;
  margin: auto;
  margin-top: 16px;
  h2 {
    color: $mdBlack;
    font-weight: 600;
    font-size: 1em;
  }

  ul {
    width: 100%;
    padding: 16px;
    background: $white;
    border-radius: 8px;
    margin-top: 16px;

    li {
      width: 100%;
      border-bottom: 1px solid $brdColor;
      margin-bottom: 8px;
      padding-bottom: 8px;

      &:last-child {
        border-bottom: none;
        margin-bottom: 0px;
        padding-bottom: 0px;
      }
    }
    .info-text {
      width: 100%;
      @extend .elm-flex;
      justify-content: space-between;
      font-size: 0.875em;
      .item {
        width: 80%;
        flex-grow: 0;
        flex-shrink: 0;
        h3 {
          color: $mdBlack;
          margin-bottom: 8px;
          font-size: inherit;
        }
        .text-date {
          color: $lightBlack;
          display: block;
          font-size: 0.875em;
        }

        &.amt-val {
          width: auto;
          flex-grow: 0;
          font-weight: bold;
          color: $mdgreen;
          font-size: inherit;
        }
        &.amt-val-deb {
          width: auto;
          flex-grow: 0;
          font-weight: bold;
          color: red;
          font-size: inherit;
        }
      }
    }
  }
}
</style>
